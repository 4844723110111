<template>
	<div class="container">
		<div class="row">
			<div class="flex_wrap">
				<div class="intro_content maf001">
					<div class="intro_top character2">
						<div class="text_guide pt-20">
							<h4 class="col_basic fw-700">{{  $language.cartel.completeCreateCartel }}</h4>
						</div>
					</div>
				</div>
				<div class="intro_btn_wrap">
					<div class="btn_area">
						<button
							@click="$emit('to', { name: 'mafia122', params: { idx: $route.params.idx, from: 'main'}})"
							class="btn_l btn_fill_blue">{{  $language.notice.manage }}</button>
						<button
							@click="$emit('to', { name: 'main'})"
							class="btn_l btn_fill_blue"
						>{{  $language.common.complete }}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mafia1191'
		, data: function(){
			return {
				program: {
					name: this.$language.common.complete_cartel_establish
					, title: this.$language.common.complete_cartel_establish
					, type: 'cartel_sub'
					, not_footer: true
					, not_header: true
				}
			}
		}

		, created() {
			this.$emit('onLoad', this.program)
		}
	}
</script>